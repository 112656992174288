import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { injectIntl, InjectedIntl, FormattedMessage } from 'react-intl';
import { Popover } from 'antd';
import {
  AppState,
  hasLocationSelector,
  hasVisibilitySelector,
  getPublishedEmergency,
  updateCreativity,
  deleteCreativity,
  changeCreativeStatus,
  Status,
} from '../../../../redux/modules';
import { Button, Modal } from '../../../../components';
import { getPlayerUrl } from '../../../../utils';

import {
  Container,
  WrapperContainer,
  WrapperButton,
  WrapperText,
  FlexContainer,
  EmergencyButtonFlexContainer,
  ButtonWrapper,
  UnpublishButtonWrapper,
  DisableDeleteButton,
  DenyCommentButton,
} from './styles';
import { UserConsumer } from '../../../../providers';

interface IProps {
  id: string;
  error?: any;
  isPublishable?: boolean;
  hasPublishedEmergency?: boolean;
  isEmergency?: boolean;
  updateCreativity?: any;
  unpublishCreativity?: any;
  intl?: InjectedIntl;
  deleteCreativity?: any;
  status?: Status;
  changeCreativeStatus?: any;
  selected?: boolean | undefined;
}

interface IState {
  visiblePublishModal: boolean;
  visibleDeleteModal: boolean;
}

class Actions extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      visiblePublishModal: false,
      visibleDeleteModal: false,
    };
    this.handleDonePublishModal = this.handleDonePublishModal.bind(this);
    this.handleCancelPublishModal = this.handleCancelPublishModal.bind(this);
    this.openModalPublish = this.openModalPublish.bind(this);
    this.openModalDelete = this.openModalDelete.bind(this);
    this.handleDoneDeleteModal = this.handleDoneDeleteModal.bind(this);
    this.handleCancelDeleteModal = this.handleCancelDeleteModal.bind(this);
  }

  handleSaveCreativity = () => this.props.updateCreativity(this.props.id, 2);

  handlePublishCreativity = () => this.props.updateCreativity(this.props.id, 4);

  handleUnpublishedCreativity = () => () =>
    this.props.unpublishCreativity(this.props.id);

  handlePlay = () => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    window.open(getPlayerUrl(this.props.id));
  };

  handleDeleteCreativity = () => this.props.deleteCreativity(this.props.id);

  getChangeStatusHandler = (status: Status) => () => {
    return this.props.changeCreativeStatus(this.props.id, status);
  };

  renderEmergencyActions = ({ isAdmin }: { isAdmin: boolean }) => {
    const { status, intl } = this.props;

    return (
      <Fragment>
        <EmergencyButtonFlexContainer>
          {isAdmin &&
            (status === Status.PUBLISHED ? (
              <Button
                type="red"
                onClick={this.getChangeStatusHandler(2)}
                button={intl!.formatMessage({
                  id: 'actions.buttons.deactivate',
                })}
                padding={20}
              />
            ) : (
              <Button
                onClick={this.openModalPublish}
                button={intl!.formatMessage({
                  id: 'actions.buttons.publish',
                })}
                padding={20}
              />
            ))}
        </EmergencyButtonFlexContainer>
      </Fragment>
    );
  };

  renderCreativeActions = ({ isAdmin }: { isAdmin: boolean }) => {
    const { isPublishable, status, intl, selected } = this.props;

    if (isAdmin) {
      if (status === Status.CREATED || status === Status.DRAFT || !status) {
        return (
          <Fragment>
            <FlexContainer>
              {selected ? (
                <Popover
                  content={intl!.formatMessage({
                    id: 'actions.buttons.popOver.isDefault',
                  })}
                  trigger="hover"
                  placement="top"
                >
                  <div>
                    <DisableDeleteButton
                      button={intl!.formatMessage({
                        id: 'actions.buttons.delete',
                      })}
                      padding={20}
                      type="delete"
                      className="disabled"
                    />
                  </div>
                </Popover>
              ) : (
                <Button
                  onClick={this.openModalDelete}
                  button={intl!.formatMessage({
                    id: 'actions.buttons.delete',
                  })}
                  padding={20}
                  type="delete"
                />
              )}
              <Button
                onClick={this.handleSaveCreativity}
                type="whiteBlue"
                button={intl!.formatMessage({
                  id: 'actions.buttons.save',
                })}
                padding={20}
              />
              <Button
                onClick={this.openModalPublish}
                button={intl!.formatMessage({
                  id: 'actions.buttons.publish',
                })}
                disabled={!isPublishable}
                padding={20}
              />
            </FlexContainer>
          </Fragment>
        );
      } else if (status === Status.PENDING_VALIDATION) {
        return (
          <Fragment>
            <FlexContainer>
              <DenyCommentButton
                onClick={this.getChangeStatusHandler(2)}
                button={intl!.formatMessage({
                  id: 'actions.buttons.denyComment',
                })}
                padding={20}
              />
              <Button
                onClick={this.getChangeStatusHandler(4)}
                button={intl!.formatMessage({
                  id: 'actions.buttons.approvePublish',
                })}
                padding={20}
              />
            </FlexContainer>
          </Fragment>
        );
      } else if (status === Status.PUBLISHED) {
        return (
          <UnpublishButtonWrapper>
            <Button
              onClick={this.getChangeStatusHandler(2)}
              button={intl!.formatMessage({
                id: 'actions.buttons.unpublish',
              })}
              padding={20}
            />
          </UnpublishButtonWrapper>
        );
      }
    } else {
      if (status === Status.CREATED || status === Status.DRAFT || status) {
        return (
          <Fragment>
            <FlexContainer>
              {selected ? (
                <Popover
                  content={intl!.formatMessage({
                    id: 'actions.buttons.popOver.isDefault',
                  })}
                  trigger="hover"
                  placement="top"
                >
                  <div>
                    <DisableDeleteButton
                      button={intl!.formatMessage({
                        id: 'actions.buttons.delete',
                      })}
                      padding={20}
                      type="delete"
                      className="disabled"
                    />
                  </div>
                </Popover>
              ) : (
                <Button
                  onClick={this.openModalDelete}
                  button={intl!.formatMessage({
                    id: 'actions.buttons.delete',
                  })}
                  padding={20}
                  type="delete"
                />
              )}
              <Button
                onClick={this.handleSaveCreativity}
                type="whiteBlue"
                button={intl!.formatMessage({
                  id: 'actions.buttons.save',
                })}
                padding={20}
              />
              <Button
                onClick={this.getChangeStatusHandler(3)}
                button={intl!.formatMessage({
                  id: 'actions.buttons.requestValidation',
                })}
                disabled={!isPublishable}
                padding={20}
              />
            </FlexContainer>
          </Fragment>
        );
      } else if (
        status === Status.PENDING_VALIDATION ||
        status === Status.PUBLISHED
      ) {
        <Fragment>
          <FlexContainer>
            <Button
              onClick={this.openModalDelete}
              button={intl!.formatMessage({
                id: 'actions.buttons.delete',
              })}
              padding={20}
              disabled
              type="delete"
            />
            <Button
              onClick={this.handleSaveCreativity}
              type="whiteBlue"
              button={intl!.formatMessage({
                id: 'actions.buttons.save',
              })}
              disabled
              padding={20}
            />
            <Button
              onClick={this.getChangeStatusHandler(3)}
              button={intl!.formatMessage({
                id: 'actions.buttons.requestValidation',
              })}
              disabled
              padding={20}
            />
          </FlexContainer>
        </Fragment>;
      }
    }
  };

  openModalDelete() {
    this.setState({ visibleDeleteModal: true });
  }

  closeModalDelete() {
    this.setState({ visibleDeleteModal: false });
  }

  openModalPublish() {
    this.setState({ visiblePublishModal: true });
  }

  closeModalPublish() {
    this.setState({ visiblePublishModal: false });
  }

  handleDonePublishModal() {
    this.handlePublishCreativity();
    this.closeModalPublish();
  }

  handleCancelPublishModal() {
    this.closeModalPublish();
  }

  handleDoneDeleteModal() {
    this.handleDeleteCreativity();
    this.closeModalDelete();
  }

  handleCancelDeleteModal() {
    this.closeModalDelete();
  }

  render() {
    const { isEmergency, intl } = this.props;
    const { visiblePublishModal, visibleDeleteModal } = this.state;

    return (
      <UserConsumer>
        {user => (
          <Fragment>
            <Modal
              title={intl!.formatMessage({
                id: 'actions.modalPublishTitle',
              })}
              handleCustomOk={this.handleDonePublishModal}
              handleCustomCancel={this.handleCancelPublishModal}
              visible={visiblePublishModal}
              noFooter={true}
            >
              <WrapperContainer>
                <WrapperText>
                  {intl!.formatMessage({
                    id: this.props.isEmergency
                      ? 'actions.emergency.modalText'
                      : 'actions.creative.modalText',
                  })}
                </WrapperText>
                <WrapperButton>
                  <Button
                    type="whiteBlue"
                    button={intl!.formatMessage({
                      id: 'actions.modalCancel',
                    })}
                    onClick={this.handleCancelPublishModal}
                  />
                  <Button
                    button={intl!.formatMessage({
                      id: 'actions.modalConfirm',
                    })}
                    onClick={this.handleDonePublishModal}
                  />
                </WrapperButton>
              </WrapperContainer>
            </Modal>

            <Modal
              title={intl!.formatMessage({
                id: 'actions.modalDeleteTitle',
              })}
              handleCustomOk={this.handleDoneDeleteModal}
              handleCustomCancel={this.handleCancelDeleteModal}
              visible={visibleDeleteModal}
              noFooter={true}
            >
              <WrapperContainer>
                <WrapperText>
                  <FormattedMessage id="actions.modalDeleteText" />
                </WrapperText>
                <WrapperButton>
                  <Button
                    type="whiteBlue"
                    button={intl!.formatMessage({
                      id: 'actions.modalCancel',
                    })}
                    onClick={this.handleCancelDeleteModal}
                  />
                  <Button
                    button={intl!.formatMessage({
                      id: 'actions.modalConfirm',
                    })}
                    onClick={this.handleDoneDeleteModal}
                  />
                </WrapperButton>
              </WrapperContainer>
            </Modal>

            <Container>
              <ButtonWrapper>
                <Button
                  onClick={this.handlePlay()}
                  type="whiteBlue"
                  button={intl!.formatMessage({
                    id: 'actions.buttons.play',
                  })}
                  padding={20}
                />
              </ButtonWrapper>
              {isEmergency
                ? this.renderEmergencyActions(user)
                : this.renderCreativeActions(user)}
            </Container>
          </Fragment>
        )}
      </UserConsumer>
    );
  }
}

export default connect(
  (state: AppState, props: IProps) => ({
    isPublishable:
      hasVisibilitySelector(state, props.id) &&
      hasLocationSelector(state, props.id),
    hasPublishedEmergency: !!getPublishedEmergency(state),
  }),
  {
    updateCreativity,
    deleteCreativity,
    changeCreativeStatus,
  }
)(injectIntl<any>(Actions));
